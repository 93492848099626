import React from "react";
import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";

// import configureStore from "./store";
import { configureToolkit } from "./store";

import "./App.scss";
import MainRouter from "./containers/Nav/MainRouter";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "firebase/messaging";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const store = configureToolkit();
export const persistor = persistStore(store);
// Initialize Firebase

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// disable retries
			retry: false,
			// disable stale data
			refetchOnWindowFocus: false,
			cacheTime: 1,
		},
	},
});
const App: React.FC = () => {
	// useEffect(() => {
	// 	getTokenFs();
	// }, []);

	// onMessageListener()
	// 	.then((res) => {
	// 		console.log(res, "res");
	// 	})
	// 	.catch((err) => {
	// 		console.log(err, "err");
	// 	});

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<MainRouter />
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;
