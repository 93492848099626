import { createReducer } from "typesafe-actions";
import { REHYDRATE, persistReducer } from "redux-persist";
import { actionDetail } from "../utils/misc";
import storage from "redux-persist/lib/storage";
import { authLoginAsyncAction, authLogoutAction } from "../actions/authAction";
import AuthReducerDto from "../dtos/auth-reducer.dto";

const initialState: AuthReducerDto = {
	authenticated: false,
	token: "",
	profile: {},
	login: {
		loading: false,
		error: "",
	},
	refreshToken: "",

	action: actionDetail,
};

const authReducer: any = persistReducer(
	{
		key: "auth",
		storage,
		whitelist: ["token", "profile"],
	},

	createReducer(initialState)
		.handleAction(REHYDRATE, (state: any, action: any): any => {
			if (action.key !== "auth") return state;
			return {
				...state,
				authenticated: !!(action.payload && action.payload.token),
			};
		})
		.handleAction(authLoginAsyncAction.request, (state: any): any => ({
			...state,
			login: { ...initialState.login, loading: true },
		}))
		.handleAction(
			authLoginAsyncAction.success,
			(state: any, { payload }: any) => ({
				...state,
				token: payload.accessToken,
				profile: payload.profile,
				refreshToken: "",
				authenticated: true,
				login: {
					loading: false,
					error: "",
				},
			})
		)
		.handleAction(
			authLoginAsyncAction.failure,
			(state: any, { payload }: any) => ({
				...state,
				login: {
					loading: false,
					error: payload,
				},
			})
		)
		.handleAction(authLogoutAction.request, (state: any) => ({
			...state,
			...initialState,
		}))

	/*
      [
        authLogoutAction,
        (state: any) => ({
          ...state,
          token: "",
          profile: {},
          authenticated: false,
        }),
      ],
    ]),
    initialState
  )*/
);

export default authReducer;
