import { any } from "prop-types";
import { LoadingIndicator } from "react-select/src/components/indicators";
import { dispatch } from "rxjs/internal/observable/pairs";
import { createAction, createAsyncAction } from "typesafe-actions";
import { LoginDto } from "../dtos/auth.dto";
import { doLogin } from "../services/auth/auth.service";
import { convertRoles } from "../utils/misc";

// create three kinds of asynchronous actions.request, failure and success
export const authLoginAsyncAction: any = createAsyncAction(
	"AUTH_LOGIN_REQUEST",
	"AUTH_LOGIN_SUCCESS",
	"AUTH_LOGIN_FAILURE"
)<LoginDto, any, Error>();

export const forgetPasswordAsyncAction: any = createAsyncAction(
	"FORGET_PASSWORD_REQUEST",
	"FORGET_PASSWORD_SUCCESS",
	"FORGET_PASSWORD_FAILURE"
)<any, any, Error>();

export const authLogoutAction: any = createAsyncAction(
	"AUTH_LOGOUT_REQUEST",
	"AUTH_LOGOUT_SUCCESS",
	"AUTH_LOGOUT_FAILURE"
)<any, any, Error>();

export const dispatchLogin = (payload: LoginDto): any => (
	dispatch: any
): any => {
	dispatch(authLoginAsyncAction.request());

	doLogin(payload)
		.then((res: any) => {
			if (res.status !== 200) {
				dispatch(authLoginAsyncAction.failure(res.data.errorMsg));
			} else {
				res.data.profile.role = convertRoles(res.data.profile.role);
				dispatch(authLoginAsyncAction.success(res.data));
			}
		})
		.catch((error: any) => {
			dispatch(authLoginAsyncAction.failure(error.response.data));
		});
};

export const dispatchLogout = () => (dispatch: any) =>
	dispatch(authLogoutAction.request());

// Create logout action
