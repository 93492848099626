import { combineReducers, compose } from "redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";

import auth from "./auth";

let composeEnhancers;
if (process.env.NODE_ENV === "production") {
	composeEnhancers = compose;
} else {
	composeEnhancers =
		(window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
		compose;
}

export interface StoreInterface {
	auth: any;
}
export const rootReducer = combineReducers<StoreInterface>({
	auth,
});
export interface ToolKit {
	reducer: any;
}

// export default function configureStore(initialState = {}) {
// 	return createStore<any, any, any, any>(
// 		rootReducer,
// 		initialState,
// 		process.env.NODE_ENV === "production"
// 			? composeEnhancers(applyMiddleware(thunk))
// 			: composeEnhancers(applyMiddleware(logger, thunk))
// 	);
// }

export const configureToolkit: any = () => {
	return configureStore({
		reducer: rootReducer,
		devTools: true,
	});
};
