import {
	ChangePasswordDto,
	LoginDto,
	ResetPasswordDto,
} from "../../dtos/auth.dto";
import { api, apiResolver } from "../helpers";

import { useMutation } from "react-query";
import { useClient } from "../useClient";

export const doLogin = (payload: LoginDto): any =>
	api("/cmsuser/v1/auth/login", "POST", payload);

/**
 *
 */
export const useForgotPassword = (options: any) => {
	const client = useClient();

	return useMutation(
		(email: string) =>
			client("/cmsuser/v1/auth/forgot-password", {
				method: "POST",
				data: { email },
			}),
		{
			onSuccess: (data) => apiResolver(data),
			...options,
		}
	);
};

export const useResetPassword = (options: any) => {
	
	return useMutation(
		(body: ResetPasswordDto) =>
			api(
				"/cmsuser/v1/auth/reset-password",
				"POST",
				{ newPassword: body.newPassword },
				"",
				"",
				options.token
			),

		{
			onSuccess: (data) => apiResolver(data),
			...options,
		}
	);
};
