import moment from "moment-timezone";
import { brazeDirectCall } from "./brazeDirectEvent";

/**
 * HTTP API endpoint for tracking events
 * Segment WRITE KEY should be converted to Base64 encoded followed with colon ":" for Authorization header
 */
const identifyApi: string = process.env
	.REACT_APP_SEGMENT_IDENTIFY_API_URL as string;
const trackApi: string = process.env.REACT_APP_SEGMENT_TRACK_API_URL as string;
const KEY: string = process.env.REACT_APP_SEGMENT_WRITE_KEY as string;
const WRITE_KEY = Buffer.from(KEY + ":", "utf8").toString("base64");
const headers = {
	Authorization: `Basic ${WRITE_KEY}`,
	"Content-Type": "application/json",
};

//Function to call all track booking events
const trackApiCall = async (event_data: any, event_name: string) => {
	const data = {
		userId: event_data["User id"],
		event: event_name,
		properties: event_data,
	};

	try {
		let response = await fetch(trackApi, {
			method: "POST",
			headers,
			body: JSON.stringify(data),
		});

		return response;
	} catch (error) {
		console.log(error);
	}
};

//Function to call all identify  events
const identifyApiCall = async (event_data: any, userId: string) => {
	const data = {
		userId: userId,
		traits: event_data,
	};
	try {
		let response = await fetch(identifyApi, {
			method: "POST",
			headers,
			body: JSON.stringify(data),
		});

		return response.status;
	} catch (error) {
		console.log(error);
	}
};

//Convert utc time to Singapore Time":
export const convertTimeToSingaporeTime = (time) => {
	let eventStartTime = moment
		.utc(time)
		.tz("Asia/Singapore")
		.format("YYYY-MM-DD");
	return eventStartTime;
};

//Get total minutes
export const getTotalMinutes = (startTime, endTime) => {
	let diff = (endTime.getTime() - startTime.getTime()) / 1000;
	diff /= 60;
	return Math.abs(Math.round(diff));
};

//Get carpark level and lot
const getCarparkLevelAndLot = (carpark: any) => {
	let carParkLevel: string[] = [];
	let carParkLot: string[] = [];

	for (let value of carpark) {
		carParkLevel.push(value.level);
		carParkLot.push(...value.lotNumbers);
	}

	let level = carParkLevel.length > 0 ? carParkLevel[0] : "";
	let lot = carParkLot;

	return {
		level,
		lot,
	};
};

//Split vehicle image url
const imgUrlSplitter = (url: string[]) => {
	let urlSplitted: string[] = [];
	if (url?.length > 0) {
		for (const value of url) {
			if (value.includes("aws.com")) {
				let splitted = value.split("aws.com")[1];
				urlSplitted.push(splitted);
			} else {
				urlSplitted.push(value);
			}
		}
	}
	return urlSplitted;
};

// Return Zero if prop value is nullish
const numberNullishProp = (prop?: number) => {
	return prop ? +prop : 0;
};

// Return empty string if prop value is nullish
const stringNullishProp = (prop?: string) => {
	return prop ? prop.toString() : "";
};

//date to iso date format
function dateToISO(date: string): string {
	if (!date || date.trim() === "" || date === "Invalid date") {
		return "";
	}
	let dateStr = date;
	let parts = dateStr.split("/");
	let myDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
	return myDate;
}

const day: string[] = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];

/**
 * Format KYC Status for segment identify call
 * @param accStatus
 * @returns the status in worded format string
 */
export const kycStatusToString = (accStatus: string): string => {
	let status: string = "";
	switch (accStatus) {
		case "0":
			status = "Awaiting Approval";
			break;
		case "1":
			status = "Verified";
			break;
		case "2":
			status = "Pending User Verification";
			break;
		case "3":
			status = "Pending External Vendor";
			break;
		case "4":
			status = "Failed Verification";
			break;
		case "5":
			status = "Duplicate User";
			break;
		default:
			break;
	}

	return status;
};

const insuranceType = (insurence: string): string => {
	let insuranceType: string = "";

	switch (insurence) {
		case "Basic":
			insuranceType = "Basic Car Insurance";
			break;
		case "CollisionDamageWaiver":
			insuranceType = "CDW";
			break;
		default:
			insuranceType = insurence;
			break;
	}

	return insuranceType;
};

//SEGMENT EVENT FOR Booking Completed
export const trackBookingCompleted = async (
	event
	// bookingDetails: any,
	// resData: any
) => {
	let d = new Date(convertTimeToSingaporeTime(event.scheduledStartTime));
	let event_date = new Date();
	const start_day = day[d.getDay()];
	let duration = getTotalMinutes(
		new Date(event.scheduledStartTime),
		new Date(event.scheduledEndTime)
	);
	const carpark = getCarparkLevelAndLot(event.carPark?.carParkLots);

	let event_data = {
		"Booking id": event.id,
		"Booking Number": event.bookingNo,
		"User id": event.userId,
		"First Booking": event.isFirstBooking,
		"Event Date Time": event_date.toISOString(),
		"Start Day": start_day,
		"Scheduled Start Time": event.scheduledStartTime,
		"Scheduled End Time": event.scheduledEndTime,
		Duration: duration,
		Currency: "SGD",
		Source: "CMS",
		"Total Amount": numberNullishProp(event.priceGroup?.amountPaid),
		"Discount Amount": numberNullishProp(event?.totalDiscount),
		Price: numberNullishProp(event.hourlyPriceGroup?.totalPrice),
		"Time Charge": event.hourlyPriceGroup.priceBreakdown
			? event.hourlyPriceGroup.priceBreakdown
					.map((val) => val.total)
					.reduce((a, b) => a + b)
			: 0,
		"Mileage Charge": event.endtripPriceGroup?.mileageAmount,
		"Extension Charge": event.extensionPriceGroup
			? event.extensionPriceGroup
					.map((val) => val.totalPrice)
					.reduce((a, b) => a + b)
			: 0,
		"Insurance Charge": event.totalInsuranceAmount,
		"Other Charges":
			event.hourlyPriceGroup.lateChargeAmount + event.extensionPriceGroup
				? event.extensionPriceGroup
						.map((val) => val.lateChargeAmount)
						.reduce((a, b) => a + b)
				: 0,
		"Car Insurance": insuranceType(
			event.hourlyPriceGroup?.insurance?.insuranceType
		),
		"Payment Method": numberNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.method === "Card" ? 1 : 2
		),
		"Promo Code": event.hourlyPriceGroup?.promocodeDiscount?.promoCode,
		"Promo Code Applied": !event.hourlyPriceGroup?.promocodeDiscount
			? false
			: true,
		"Promo Code Applied On": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountApplicableType
		),
		"Promo Code Type": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountType
		),
		"Card BIN": stringNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.details?.bin
		),
		"Carpark id": stringNullishProp(event.carPark?.id),
		"Carpark Name": stringNullishProp(event.carPark?.name),
		"Carpark Address": stringNullishProp(event.carPark?.address),
		"Carpark Latitude": numberNullishProp(event.carPark?.latitude),
		"Carpark Longitude": numberNullishProp(event.carPark?.longitude),
		"Carpark Area Code": stringNullishProp(event.carPark?.postalCode),
		"Carpark Level": carpark.level,
		"Carpark Lot": carpark.lot,
		Mileage: numberNullishProp(event.hourlyPriceGroup.mileagePrice),
		"Vehicle Id": stringNullishProp(event.vehicle?.id),
		"Vehicle Make": stringNullishProp(event.vehicle?.vehicleMakeName),
		"Vehicle Model": stringNullishProp(event.vehicle?.vehicleModelName),
		"Vehicle Plate Number": stringNullishProp(event.vehicle.plateNumber),
		"Vehicle Image": imgUrlSplitter(event.vehicle?.vehicleImages),
	};

	await trackApiCall(event_data, "Booking Completed");
	brazeDirectCall(event_data, "Booking Completed");
};

export const identifyBookingCompleted = async (event: any) => {
	let traits = {
		"Last Booking Completed Date": event.userEndedTime,
		"Current Ongoing Booking": false,
		userId: event.userId,
	};
	await identifyApiCall(traits, event.userId);
};

export const identifyEndBookingSuspended = async (event: any) => {
	let traits = {
		"Last Booking Completed Date": event.userEndedTime,
		"Current Ongoing Booking": false,
		"Account Status": "Suspended",
		userId: event.userId,
	};

	await identifyApiCall(traits, event.userId);
};

//SEGMENT EVENT FOR BOOKING CANCELLED
export const trackBookingCancelled = async (event: any) => {
	const carpark = getCarparkLevelAndLot(event.carPark?.carParkLots);
	let event_date = new Date();
	let d = new Date(convertTimeToSingaporeTime(event.scheduledStartTime));

	const start_day = day[d.getDay()];
	let event_data = {
		"Booking id": event.id,
		"Booking Number": event.bookingNo,
		"User id": event.userId,
		"Event Date Time": event_date.toISOString(),
		"First Booking": event.isFirstBooking,
		"Start Day": start_day,
		"Scheduled Start Time": event.scheduledStartTime,
		"Scheduled End Time": event.scheduledEndTime,
		Currency: "SGD",
		Source: "CMS",
		"Cancellation Type": "admin_cancelled",
		"Cancellation Charge": numberNullishProp(
			event.cancelledPriceGroup?.cancellationAmount
		),
		"Cancellation Reason": event.reason,
		"Total Refund Amount":
			numberNullishProp(event.refundedAmount) !== 0
				? -event.refundedAmount
				: 0,
		"Total Amount": numberNullishProp(event.refundedAmount),
		"Discount Amount": numberNullishProp(event?.totalDiscount),
		"Time Charge": numberNullishProp(
			event.hourlyPriceGroup.priceBreakdown
				? event.hourlyPriceGroup.priceBreakdown
						.map((val) => val.total)
						.reduce((a, b) => a + b)
				: 0
		),
		"Mileage Charge": numberNullishProp(
			event.hourlyPriceGroup.mileageAmount
		),
		"Extension Charge": numberNullishProp(
			event.extensionPriceGroup
				? event.extensionPriceGroup
						.map((val) => val.totalPrice)
						.reduce((a, b) => a + b)
				: 0
		),
		"Insurance Charge": numberNullishProp(event.totalInsuranceAmount),
		"Other Charges":
			event.hourlyPriceGroup.lateChargeAmount + event.extensionPriceGroup
				? event.extensionPriceGroup
						.map((val) => val.lateChargeAmount)
						.reduce((a, b) => a + b)
				: 0,
		"Car Insurance": insuranceType(
			event.hourlyPriceGroup?.insurance?.insuranceType
		),
		"Payment Method": numberNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.method === "Card" ? 1 : 2
		),
		"Card BIN": stringNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.details?.bin
		),
		"Promo Code": event.hourlyPriceGroup?.promocodeDiscount?.promoCode,
		"Promo Code Applied": !event.hourlyPriceGroup?.promocodeDiscount
			? false
			: true,
		"Promo Code Applied On": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountApplicableType
		),
		"Promo Code Type": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountType
		),
		"Carpark id": stringNullishProp(event.carPark?.id),
		"Carpark Name": stringNullishProp(event.carPark?.name),
		"Carpark Address": stringNullishProp(event.carPark?.address),
		"Carpark Latitude": numberNullishProp(event.carPark?.latitude),
		"Carpark Longitude": numberNullishProp(event.carPark?.longitude),
		"Carpark Area Code": stringNullishProp(event.carPark?.postalCode),
		"Carpark Level": carpark.level,
		"Carpark Lot": carpark.lot,
		"Vehicle Id": stringNullishProp(event.vehicle?.id),
		"Vehicle Make": stringNullishProp(event.vehicle?.vehicleMakeName),
		"Vehicle Model": stringNullishProp(event.vehicle?.vehicleModelName),
		"Vehicle Plate Number": stringNullishProp(event.vehicle.plateNumber),
		"Vehicle Image": imgUrlSplitter(event.vehicle?.vehicleImages),
	};

	await trackApiCall(event_data, "Booking Cancelled");
	await brazeDirectCall(event_data, "Booking Cancelled");
};

export const identifyCancelledBookingSuspended = (event: any) => {
	let traits = {};
	if (event.status === "Ongoing") {
		traits = {
			"Account Status": "Suspended",
			"Current Ongoing Booking": false,
			userId: event.userId,
		};
	} else if (event.status === "Confirmed") {
		traits = {
			"Account Status": "Suspended",
		};
	}
	identifyApiCall(traits, event.userId);
};

export const identifyCancelledBookingSuccess = (event: any) => {
	let traits = {
		"Current Ongoing Booking": false,
		userId: event.userId,
	};
	identifyApiCall(traits, event.userId);
};

//SEGMENT EVENT FOR BOOKING EXTENDED
export const trackBookingExtended = async (event: any) => {
	let event_date = new Date();
	let event_data = {
		"Booking id": event.id,
		"Booking Number": event.bookingNo,
		"User id": event.userId,
		"Event Date Time": event_date.toISOString(),
		Source: "CMS",
		"Scheduled End Time": event.scheduledEndTime,
	};

	let response = await trackApiCall(event_data, "Booking Extended");
	return response?.status;
};

//SEGMENT EVENT FOR BOOKING CONFIRMED
export const trackBookingConfirmed = (event: any) => {
	try {
		let d = new Date(convertTimeToSingaporeTime(event.scheduledStartTime));

		const start_day = day[d.getDay()];
		let event_date = new Date();

		const carpark = getCarparkLevelAndLot(event.carPark?.carParkLots);

		let duration = getTotalMinutes(
			new Date(event.scheduledStartTime),
			new Date(event.scheduledEndTime)
		);

		let event_data = {
			"Booking id": event.id,
			"Booking Number": event.bookingNo,
			"User id": event.userId,
			"First Booking": event.isFirstBooking,
			"Event Date Time": event_date.toISOString(),
			"Start Day": start_day,
			"Scheduled Start Time": event.scheduledStartTime,
			"Scheduled End Time": event.scheduledEndTime,
			Duration: duration,
			Currency: "SGD",
			Source: "CMS",
			"Total Amount": numberNullishProp(
				event.hourlyPriceGroup?.amountPaid
			),
			"Discount Amount": numberNullishProp(event?.totalDiscount),
			Price: numberNullishProp(event.hourlyPriceGroup?.totalPrice),
			"Car Insurance": insuranceType(
				event.hourlyPriceGroup?.insurance?.insuranceType
			),
			"Payment Method": numberNullishProp(
				event.hourlyPriceGroup?.paymentMethod?.method === "Card" ? 1 : 2
			),
			"Card BIN": stringNullishProp(
				event.hourlyPriceGroup?.paymentMethod?.details?.bin
			),
			"Promo Code": stringNullishProp(
				event.hourlyPriceGroup?.promocodeDiscount?.promoCode
			),
			"Promo Code Applied": !event.hourlyPriceGroup?.promocodeDiscount
				? false
				: true,
			"Promo Code Applied On": stringNullishProp(
				event.hourlyPriceGroup?.promocodeDiscount
					?.discountApplicableType
			),
			"Promo Code Type": stringNullishProp(
				event.hourlyPriceGroup?.promocodeDiscount?.discountType
			),
			"Carpark id": stringNullishProp(event.carPark?.id),
			"Carpark Name": stringNullishProp(event.carPark?.name),
			"Carpark Address": stringNullishProp(event.carPark?.address),
			"Carpark Latitude": numberNullishProp(event.carPark?.latitude),
			"Carpark Longitude": numberNullishProp(event.carPark?.longitude),
			"Carpark Area Code": stringNullishProp(event.carPark?.postalCode),
			"Carpark Level": carpark.level,
			"Carpark Lot": carpark.lot,
			"Vehicle id": stringNullishProp(event.vehicle?.id),
			"Vehicle Make": stringNullishProp(event.vehicle?.vehicleMakeName),
			"Vehicle Model": stringNullishProp(event.vehicle?.vehicleModelName),
			"Vehicle Plate Number": stringNullishProp(
				event.vehicle.plateNumber
			),
			"Vehicle Image": imgUrlSplitter(event.vehicle?.vehicleImages),
		};
		trackApiCall(event_data, "Booking Confirmed");
	} catch (err) {
		console.log(err);
	}
};

//SEGMENT EVENT FOR BOOKING MODIFIED
export const trackBookingModified = async (event: any) => {
	let event_date = new Date();
	let d = new Date(convertTimeToSingaporeTime(event.scheduledStartTime));

	const start_day = day[d.getDay()];

	const carpark = getCarparkLevelAndLot(event.carPark?.carParkLots);

	let event_data = {
		"Booking id": event.id,
		"Booking Number": event?.bookingNo,
		"User id": event.userId,
		"Event Date Time": event_date.toISOString(),
		"Start Day": start_day,
		"Scheduled Start Time": event.scheduledStartTime,
		"Scheduled End Time": event.scheduledEndTime,
		Currency: "SGD",
		Source: "CMS",
		"Total Amount": numberNullishProp(event.hourlyPriceGroup?.amountPaid),
		"Discount Amount": numberNullishProp(event?.totalDiscount),
		Price: numberNullishProp(event.hourlyPriceGroup?.totalPrice),
		"Car Insurance": insuranceType(
			event.hourlyPriceGroup?.insurance?.insuranceType
		),
		"Payment Method": numberNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.method === "Card" ? 1 : 2
		),
		"Card BIN": stringNullishProp(
			event.hourlyPriceGroup?.paymentMethod?.details?.bin
		),
		"Promo Code": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.promoCode
		),
		"Promo Code Applied": !event.hourlyPriceGroup?.promocodeDiscount
			? false
			: true,
		"Promo Code Applied On": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountApplicableType
		),
		"Promo Code Type": stringNullishProp(
			event.hourlyPriceGroup?.promocodeDiscount?.discountType
		),
		"Carpark id": stringNullishProp(event.carPark?.id),
		"Carpark Name": stringNullishProp(event.carPark?.name),
		"Carpark Address": stringNullishProp(event.carPark?.address),
		"Carpark Latitude": numberNullishProp(event.carPark?.latitude),
		"Carpark Longitude": numberNullishProp(event.carPark?.longitude),
		"Carpark Area Code": stringNullishProp(event.carPark?.postalCode),
		"Carpark Level": carpark.level,
		"Carpark Lot": carpark.lot,
		"Vehicle id": stringNullishProp(event.vehicle?.id),
		"Vehicle Make": stringNullishProp(event.vehicle?.vehicleMakeName),
		"Vehicle Model": stringNullishProp(event.vehicle?.vehicleModelName),
		"Vehicle Plate Number": stringNullishProp(event.vehicle.plateNumber),
		"Vehicle Image": imgUrlSplitter(event.vehicle?.vehicleImages),
	};

	await trackApiCall(event_data, "Booking Modified");
};

export const identifyFirstBookingFailed = (event: any) => {
	const event_data = {
		"First Booking Failed": event.firstBookingFailed,
	};

	identifyApiCall(event_data, event.userId);
};

/**
 * Start section for Segment Call for KYC Related Events
 */

//SEGMENT EVENT FOR IDENTIFY CALLS ON ACCOUNT STATUS CHANGE
export const identifyAccountStatus = async (event: any) => {
	let event_data = {
		"Account Status": event.status,
	};

	await identifyApiCall(event_data, event.userId);
};

//SEGMENT EVENT FOR IDENTIFY CALLS ON ACCOUNT STATUS CHANGE FOR MASS BAN AND UNBAN
export const massIdentifyAccountStatus = async (event: any, status: string) => {
	for (const i of event) {
		await identifyApiCall(
			{
				"Account Status": status,
			},
			i.userId
		);
	}
};

//Identify call for KYC status changes
export const identifyKycStatusChange = (event: any) => {
	let event_data = {
		"KYC Status": event.status,
	};

	let res = identifyApiCall(event_data, event.id);
	return res;
};

//Identify call for Account Registration Approved events
export const identifyAccountApproved = async (event: any) => {
	let event_data = {
		"Account Status": event.status,
		"Account Approval Date": new Date(),
	};

	await identifyApiCall(event_data, event.userId);
};

//Identify call for edit profile events
export const identifyAccountEditProfile = async (event: any) => {
	const { address, postalCode, unitNo } = event;
	const city = "Singapore";
	const country = "Singapore";
	const state = "Singapore";
	const licenseExpiryDate = event.drivingLicenceExpiry
		? dateToISO(event.drivingLicenceExpiry)
		: "";
	let updatedLicenseType = event.drivingClassTypes.map((licence) => {
		return {
			type: `Class ${licence.drivingClassType}`,
			"issued date": dateToISO(licence.drivingLicenceIssue),
			"expiry date": licenseExpiryDate,
		};
	});

	let event_data = {
		email: stringNullishProp(event.email),
		phone: `+65${event.phoneNumber}`,
		address: {
			city,
			country,
			street: `${stringNullishProp(address)}, ${stringNullishProp(
				unitNo
			)}`,
			postalCode: stringNullishProp(postalCode),
			state,
		},
		"Preferred Name": stringNullishProp(event.preferredName),
		name: event.fullName,
		"Driver Licence": updatedLicenseType,
		gender: stringNullishProp(event.gender),
		birthday: dateToISO(event.dateOfBirth),
		"Area Code": !postalCode ? "" : postalCode.substring(0, 2),
	};

	await identifyApiCall(event_data, event.id);
};

//Track call for Account Registration Approved events
export const trackAccountApprovedJumio = async (event: any) => {
	try {
		let event_data = {
			"User id": event.userId,
			"Event Date Time": new Date(),
			"Registration Source": "App",
			"Registration Mode": "Jumio",
		};

		await trackApiCall(event_data, "Account Registration Approved");
	} catch (err) {
		console.log(err);
	}
};
