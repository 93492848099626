import moment from "moment";
import OptionDto from "../dtos/selectOption.dto";
import {
	identifyBookingCompleted,
	identifyCancelledBookingSuccess,
	identifyCancelledBookingSuspended,
	identifyEndBookingSuspended,
	trackBookingCancelled,
	trackBookingCompleted,
	trackBookingConfirmed,
	trackBookingExtended,
	trackBookingModified,
} from "../services/brazeSegment";

//check csv ext for fromik validation
export const isCsvExtension = (value) => {
	const name = value.name;
	const lastDot = name.lastIndexOf(".");
	const extension = name.substring(lastDot + 1);
	return extension === "csv";
};

export const isSidebarOpened = () => {
	const rule1 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-lg-show").length;

	const rule2 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-show").length;

	return rule1 + rule2 > 0;
};

/**
 * Check the type of the error and return error message
 *
 * @param err
 * @returns string
 */
export const extractErrorMsg = (err: any) => {
	if (err.response?.data ) {
		
		//
		let errorList: any = [];
		if (err.response.data.message === "ValidationError") {
			for (const attribute in err.response.data.data) {
				if (
					Array.isArray(err.response.data.data[attribute]) &&
					err.response.data.data[attribute].length > 0
				) {
					const errorMessages = err.response.data.data[attribute].map(
						(errorMessage, index) => {
							return `${errorMessage}${
								err.response.data.data[attribute].length > 1
									? ". "
									: ""
							}`;
						}
					);
					errorList.push(errorMessages);
				}
			}

			if (errorList.length > 0) {
				const errorData = errorList[0]
					.map((errorMessage, index) => `${errorMessage}`)
					.join(" ");

				return errorData;
			} else {
				return err.response.data.message;
			}
		} else if (err.response.data.message) {
			return err.response.data.message;
		} else {
			return `Error ${err.response.status}: Something went wrong, please try again later!`;
		}
	} else if (typeof err === "string") {
		// String error message
		return err;
	} else if (
		err.response &&
		err.response.status === 500 &&
		err.response.data
	) {
		// Backend error
		return err.response.data;
	} else if (
		err.response &&
		err.response.status !== 500 &&
		err.response.data
	) {
		// Backend error with status other than 500
		return err.response.data.errorMsg;
	} else if (err.errorMsg) {
		// Payload error with errorMsg field
		return err.errorMsg;
	} else if (err.message) {
		return err.message;
	} else if (err instanceof Error) {
		// React Query error
		return err.message;
	} else {
		// Fallback message for other cases
		return "Something went wrong, please try again later!";
	}
};

export const formatDateTime = (date: Date) =>
	date ? moment(date).format("DD/MM/YYYY HH:mm") : "";

export const formatDateRange = (date: any) => {
	const [from, to] = date.split("~").map((x: string) => x.trim());

	return `${from ? moment(from).format("YYYY-MM-DD") : ""},${
		to ? moment(to).format("YYYY-MM-DD") : ""
	}`;
};

export const fileTodataURL = async (file: any, callback) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			const dataUrl = reader.result;
			resolve(dataUrl);
			callback(dataUrl);
		};

		reader.onerror = (error) => {
			reject(error);
		};
	});
};

export function dataURLtoFile(dataurl: any, filename: any) {
	const arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]);

	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export function downloadFile(data: any, filename: any) {
	const url = window.URL.createObjectURL(data.response);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
}

export const actionDetail = {
	loading: false,
	error: "",
	success: false,
};

export const defaultRequestParams = {
	page: 1,
	size: 20,
};

/**
 * structure data from api to table props
 * return fallback values to table if data was null
 *
 * @param data
 */
export const decorateTableProps = (data: any) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}
	return {
		data: data.data?.dataList
			? data.data.dataList
			: data.data.data || data.data,
		pagination: {
			pageSize: data.data.pageSize,
			totalItems: data.data.totalCount,
			totalPages: data.data.totalPage,
			currentPage: data.data.pageNumber,
		},
	};
};

export const decorateWithoutPaging = (data) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	} else if (data.data) {
		return {
			data: data.data,
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	} else {
		return {
			data,
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}
};

export const decorateTablePropsV2 = (data: any) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}
	return {
		data: data?.data?.dataList ? data?.data?.dataList : data?.data,
		pagination: {
			pageSize: data.pageSize,
			totalItems: data.totalCount,
			totalPages: data.totalPage,
			currentPage: data.pageNumber,
		},
	};
};

export const referralHistory = (data: any) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 20,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}
	return {
		data: data?.data?.dataList ? data?.data?.dataList : data,
		// pagination: {
		// 	pageSize: data.pageSize,
		// 	totalItems: data.totalCount,
		// 	totalPages: data.totalPage,
		// 	currentPage: data.pageNumber,
		// },
	};
};

export const transformToSelectOptions = (
	data: Array<any>,
	valueIndex: string = "id",
	labelIndex: string = "name"
): Array<OptionDto> => {
	return data.map((item) => ({
		value: item[valueIndex],
		label: item[labelIndex],
	}));
};

/**
 * get left and top offset of a specific html element
 *
 * @param HTMLELEMENT
 * @returns @Object { top: number, left: number }
 */
export const getOffset = (el: any) => {
	var _x = 0;
	var _y = 0;
	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
};

// Generate Timeline
export const generateMomentTimeline = ({
	interval, // moment duration object
	from, // moment duration object
	to, // moment duration object
	unit, // moment duration unit
}) => {
	const durationFrom = moment.duration(from, unit);
	const durationTo = moment.duration(to, unit);

	const values: any = [];

	const getTimelines = (current: any = durationFrom.clone()) => {
		values.push(current);

		const next = current.clone().add(interval);

		if (next.asMilliseconds() < durationTo.asMilliseconds()) {
			getTimelines(next);
		}
	};

	getTimelines();

	return values;
};

export const arrayRepeat = (arr, times) => {
	return new Array(times).fill(arr);
};

/**
 * Helper to render nodes
 *
 * @param value
 * @returns
 */
export const renderJSXNode = (value) => {
	return typeof value === "function" ? value() : value;
};

// Get element's CSS property value
export const getElementStyle = (el, styleProp) => {
	var value,
		defaultView = (el.ownerDocument || document).defaultView;
	// W3C standard way:
	if (defaultView && defaultView.getComputedStyle) {
		// sanitize property name to css notation
		// (hypen separated words eg. font-Size)
		styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
		return defaultView
			.getComputedStyle(el, null)
			.getPropertyValue(styleProp);
	} else if (el.currentStyle) {
		// IE
		// sanitize property name to camelCase
		styleProp = styleProp.replace(/\-(\w)/g, function (str, letter) {
			return letter.toUpperCase();
		});
		value = el.currentStyle[styleProp];
		// convert other units to pixels on IE
		if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
			return (function (value) {
				var oldLeft = el.style.left,
					oldRsLeft = el.runtimeStyle.left;
				el.runtimeStyle.left = el.currentStyle.left;
				el.style.left = value || 0;
				value = el.style.pixelLeft + "px";
				el.style.left = oldLeft;
				el.runtimeStyle.left = oldRsLeft;
				return value;
			})(value);
		}
		return value;
	}
};

/**
 * Get strips tags
 * @param html
 * @returns string
 */
export const stripTags = (html: any) => {
	return html.replace(/<[^>]*>?/gm, "");
};

export const calcDuration = (startTime, endTime) => {
	const endTimeFormat = moment(endTime, "YYYY-MM-DDTHH:mm:ss");
	const startTimeFormatted = moment(startTime, "YYYY-MM-DDTHH:mm:ss");

	const duration = moment.duration(endTimeFormat.diff(startTimeFormatted));
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();

	return {
		days,
		hours,
		minutes,
	};
};

export const calcExtensionDuration = (extension) => {
	const pluralize = (value, singular, plural) =>
		value === 1 ? singular : plural;

	const isExtensionValueNonZero = Object.values(extension).some(
		(value) => value !== 0
	);

	if (!isExtensionValueNonZero) {
		return "-";
	}

	const days = extension?.days;
	const hours = extension?.hours;
	const minutes = extension?.minutes;

	const parts: string[] = [];

	if (days > 0) {
		parts.push(`${days} ${pluralize(days, "day", "days")}`);
	}
	if (hours > 0) {
		parts.push(`${hours} ${pluralize(hours, "hour", "hours")}`);
	}
	if (minutes > 0) {
		parts.push(`${minutes} ${pluralize(minutes, "minute", "minutes")}`);
	}

	return parts.length > 0 ? parts.join(" ") : "-";
};

export const splitCamelCaseAndCapitalize = (inputString) => {
	// Use a regular expression to split CamelCase and capitalize the first letter of each word
	return inputString
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

export const capitalizeFirstLetter = (inputString) => {
	// Check if the input string is not empty
	if (inputString.length === 0) {
		return inputString;
	}

	// Capitalize the first letter and append the rest of the string
	return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

interface fallbackRetryProps {
	id: string;
	client: (string) => any;
	event: "create" | "postExtend" | "end" | "cancel" | "amend";
	segmentFunc: () => any;
	interval?: number;
	retry?: number;
	setError: (string) => any;
	setLoading: (boolean) => any;
	dismiss?: any;
	refresh: () => any;
	userId?: number;
	suspend?: any;
}

export const fallbackRetry = ({
	id,
	client,
	event,
	segmentFunc,
	interval = 3000,
	retry = 3,
	setError,
	setLoading,
	dismiss = () => {},
	refresh,
	userId,
	suspend,
}: fallbackRetryProps) => {
	let retries = 0;
	let errorMessage = "Status call failed, please try again.";
	setError("");
	setLoading(true);
	let url = `/booking/v1/${id}/status?FlowIdentifier=${event}`;
	const intervalId = setInterval(async function () {
		try {
			const res = await client(url);

			if (res.status === 200 && res.data.status === 2) {
				// Status call successful
				clearInterval(intervalId);
				await segmentFunc();
				setLoading(false);
				
				if (event === "end") {
					const fetchUser = await client(
						`/user/v1/profile?userId=${userId}`
					);

					if (fetchUser.data.status === "Suspend") {
						suspend.setWarningMessage(fetchUser.data.banReason);
						suspend.setShowWarningModal(true);
					} else {
						dismiss();
						refresh();
					}
				} else if (event === "amend") {
					dismiss();
					window.location.replace(
						`/booking-management/details/${res.data.bookingId}`
					);
				} else {
					dismiss();
					refresh();
				}
			} else if (res.status === 200 && res.data.errorCode === 21075) {
				// Status call failed
				clearInterval(intervalId);
				setError(res.data.errorMessage);
				setLoading(false);
			} else if (
				res.status === 200 &&
				typeof res.data.bookingId === "number"
			) {
				window.location.replace(
					`/booking-management/details/${res.data.bookingId}`
				);
			} else {
				// status call pending
				retries++;
				console.log("Refetching no error...: Retry no." + retries);
			}
			errorMessage = res.data.errorMessage;
		} catch (err) {
			// status call pending
			console.log(err);
			retries++;
			console.log("Refetching because error...: Retry no." + retries);
		}
		if (retries >= retry) {
			// status call hit max retry, retry required
			clearInterval(intervalId);
			setError(errorMessage);
			setLoading(false);
		}
	}, interval);
};

export const segmentDataCall = async (id, func, event, reason = "") => {
	let bookingData = await func(`/aggregator/v1/bookingDetails/${id}`);
	let segmentCall;
	let identifyCall;
	switch (event) {
		case "create":
			segmentCall = trackBookingConfirmed;
			identifyCall = () => {};
			break;
		case "cancelOngoing":
			bookingData.data.status = "Ongoing";
			bookingData.data.reason = reason;
			segmentCall = trackBookingCancelled;
			// IF outstanding amount identifyCancelledBookingSuspended
			if (bookingData.data.outstandingAmount) {
				identifyCall = identifyCancelledBookingSuspended;
			} else {
				identifyCall = identifyCancelledBookingSuccess;
			}
			break;
		case "cancelConfirmed":
			bookingData.data.status = "Confirmed";
			bookingData.data.reason = reason;
			segmentCall = trackBookingCancelled;
			// IF outstanding amount identifyCancelledBookingSuspended
			if (bookingData.data.outstandingAmount) {
				identifyCall = identifyCancelledBookingSuspended;
			} else {
				identifyCall = identifyCancelledBookingSuccess;
			}

			break;
		case "extend":
			if (bookingData.data.status === "Confirmed") {
				segmentCall = trackBookingModified;
				identifyCall = () => {};
			} else {
				segmentCall = trackBookingExtended;
				identifyCall = () => {};
			}
			break;
		case "end":
			segmentCall = trackBookingCompleted;
			// IF outstanding amount identifyEndBookingSuspended
			if (bookingData.data.outstandingAmount) {
				identifyCall = identifyEndBookingSuspended;
			} else {
				identifyCall = identifyBookingCompleted;
			}
			break;
		default:
			segmentCall = () => {};
			identifyCall = () => {};
			break;
	}

	try {
		await segmentCall({ ...bookingData.data });
		await identifyCall({ ...bookingData.data });
	} catch (error) {
		console.log(error);
		return;
	}
};

export const handleLicenceEnum = (licenceType) => {
	let type = "";
	switch (licenceType) {
		case "1":
			type = "3";
			break;
		case "2":
			type = "3A";
			break;
		case "3":
			type = "3C";
			break;
		case "4":
			type = "3CA";
			break;
		case "5":
			type = "2";
			break;
		case "6":
			type = "2A";
			break;
		case "7":
			type = "2B";
			break;
		case "8":
			type = "4";
			break;
		case "9":
			type = "4A";
			break;
		case "10":
			type = "5";
			break;
		case "11":
			type = "IDP";
			break;
	}
	return type;
};

export const convertRoles = (userRole) => {
	let role = "";
	switch (userRole) {
		case "admin":
			role = "admin";
			break;
		case "cx":
			role = "customer experience";
			break;
		case "cxlead":
			role = "cx lead";
			break;
		case "fin":
			role = "finance";
			break;
		case "mkt":
			role = "marketing";
			break;
		case "ops":
			role = "operations";
			break;
		case "operator":
			role = "operator";
			break;
		case "tns":
			role = "trust and safety";
			break;
	}
	return role;
};
