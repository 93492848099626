const BRAZE_KEY: string = process.env.REACT_APP_BRAZE_API_KEY as string;
const BRAZE_APP_ID: string = process.env.REACT_APP_BRAZE_APP_ID as string;
const endpoint : string = process.env.REACT_APP_BRAZE_TRACK_API_URL as string;
const headers = {
	Authorization: "Bearer " + BRAZE_KEY,
	"Content-Type": "application/json",
};

/**
 * Convert total duration in minutes to hours
 * @param totalMinutes
 * @returns "hh:mm"
 */
function toHoursAndMinutes(totalMinutes: number): string {
	const minutes = totalMinutes % 60;
	const hours = Math.floor(totalMinutes / 60);

	return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function padTo2Digits(num: number) {
	return num.toString().padStart(2, "0");
}

/*
convert payment type to either credit card or paylah string
*/
function paymentMethod(type: any): string {
	let payment_method: string = "";
	switch (type) {
		case 1:
			payment_method = "Credit Card";
			break;
		case 2:
			payment_method = "DBS Paylah";
			break;
		default:
			break;
	}
	return payment_method;
}

export const brazeDirectCall = async (data: any, name: string) => {
	data["Carpark Area Code"] = data["Carpark Area Code"]?.substring(0, 2);
	data["Payment Method"] = paymentMethod(data["Payment Method"]);
	data["Carpark Lot"] = data["Carpark Lot"]?.toString();

	if (name === "Booking Completed") {
		data["Duration"] = toHoursAndMinutes(data["Duration"]);
	}

	const dataEvents = [
		{
			app_id: BRAZE_APP_ID,
			external_id: data["User id"],
			name,
			time: new Date(),
			properties: data,
		},
	];

	const events = {
		events: dataEvents,
	};

	try {
		let response = await fetch(endpoint, {
			method: "POST",
			headers,
			body: JSON.stringify(events),
		});

		return response;
	} catch (error) {
		console.log(error);
	}
};
