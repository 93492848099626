import { lazy } from "react";

export const Login = lazy(() => import("./Login/Login"));

export const ForgotPassword = lazy(() =>
	import("./ForgotPassword/ForgotPassword")
);

export const ResetPassword = lazy(() =>
	import("./ResetPassword/ResetPassword")
);

export const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

export const ChangePassword = lazy(() =>
	import(
		"./AdminManagement/ChangePasswordFormBuilder/ChangePasswordFormBuilder"
	)
);

export const AuditTrails = lazy(() =>
	import("./AdminManagement/AuditTrails/AuditTrails")
);

export const MasterData = lazy(() =>
	import("./AdminManagement/MasterDataBuilder/MasterDataBuilder")
);
export const ExportFilePassword = lazy(() =>
	import("./AdminManagement/ExportFilePassword/ExportFilePassword")
);
export const VehicleType = lazy(() =>
	import("./AdminManagement/VehicleType/VehicleType")
);

export const PublicHoliday = lazy(() =>
	import("./AdminManagement/PublicHoliday/PublicHoliday")
);

export const PromotionalDay = lazy(() =>
	import("./AdminManagement/PromotionalDay/PromotionalDay")
);

export const VehicleColor = lazy(() =>
	import("./AdminManagement/VehicleColor/VehicleColor")
);

export const VehicleFuelType = lazy(() =>
	import("./AdminManagement/VehicleFuelType/VehicleFuelType")
);

export const VehicleAssetOwner = lazy(() =>
	import("./AdminManagement/VehicleAssetOwner/VehicleAssetOwner")
);

export const VehicleParkOwner = lazy(() =>
	import("./AdminManagement/VehicleParkOwner/VehicleParkOwner")
);

export const VehicleMake = lazy(() =>
	import("./AdminManagement/VehicleMake/VehicleMake")
);

export const TransactionManagement = lazy(() =>
	import("./TransactionManagement/TransactionManagement")
);

export const FuelCardManagement = lazy(() =>
	import("./FuelCardManagement/FuelCardManagement")
);

export const CreateFuelCard = lazy(() =>
	import("./FuelCardManagement/CreateFuelCard")
);

export const EditFuelCard = lazy(() =>
	import("./FuelCardManagement/EditFuelCard")
);

export const CarModel = lazy(() =>
	import("./AdminManagement/VehicleModel/VehicleModel")
);

export const VehiclePhoto = lazy(() =>
	import("./AdminManagement/VehiclePhoto/VehiclePhoto")
);

export const CreateVehiclePhoto = lazy(() =>
	import("./AdminManagement/VehiclePhoto/CreateVehiclePhoto")
);

export const EditVehiclePhoto = lazy(() =>
	import("./AdminManagement/VehiclePhoto/EditVehiclePhoto")
);

export const ContactUsTopic = lazy(() =>
	import("./AdminManagement/ContactUsTopic/ContactUsTopic")
);

export const PriceGroupBracket = lazy(() =>
	import("./AdminManagement/PriceGroupBracket/PriceGroupBracket")
);

export const FuelCompany = lazy(() =>
	import("./AdminManagement/FuelCompany/FuelCompany")
);

export const Announcement = lazy(() => import("./Announcement/Announcement"));

export const CreateAnnouncement = lazy(() =>
	import("./Announcement/CreateAnnouncement")
);

export const EditAnnouncement = lazy(() =>
	import("./Announcement/EditAnnouncement")
);

export const NotificationManagement = lazy(() =>
	import("./PushNotification/PushNotification")
);

export const CreateNotification = lazy(() =>
	import("./PushNotification/CreateNotification")
);

export const EditNotification = lazy(() =>
	import("./PushNotification/EditNotification")
);

export const PriceGroupManagement = lazy(() =>
	import("./PriceManagement/PriceManagement")
);

export const EditPriceManagement = lazy(() =>
	import("./PriceManagement/EditPriceManagement/EditPriceManagement")
);

export const CreatePriceManagement = lazy(() =>
	import("./PriceManagement/CreatePriceManagement/CreatePriceManagement")
);

export const ContactUsManagement = lazy(() =>
	import("./ContactUsManagement/ContactUsManagement")
);

export const CarparkManagement = lazy(() =>
	import("./CarparkManagement/CarparkManagement")
);

export const CarparkDetails = lazy(() =>
	import("./CarparkManagement/CarparkDetails/CarparkDetails")
);

export const CreateCarpark = lazy(() =>
	import("./CarparkManagement/CreateCarpark")
);

export const EditCarpark = lazy(() =>
	import("./CarparkManagement/EditCarpark")
);

export const CreateParkingLot = lazy(() =>
	import("./CarparkManagement/ParkingLots/CreateParkingLot")
);

export const EditParkingLot = lazy(() =>
	import("./CarparkManagement/ParkingLots/EditParkingLot")
);

export const CarManagement = lazy(() =>
	import("./CarManagement/CarManagement")
);

export const AccountManagement = lazy(() =>
	import("./AccountManagement/AccountManagement")
);

export const MassBan = lazy(() =>
	import("./AccountManagement/MassBan/MassBan")
);
export const MassUnban = lazy(() =>
	import("./AccountManagement/MassBan/MassUnban")
);

export const AccountDetails = lazy(() =>
	import("./AccountManagement/AccountDetails/AccountDetails")
);
export const AccountLog = lazy(() =>
	import("./AccountManagement/AccountDetails/AccountLog/AccountLog")
);
export const MyAccount = lazy(() =>
	import("./AccountManagement/MyAccount/MyAccount")
);
export const MyInbox = lazy(() =>
	import("./AccountManagement/MyInbox/MyInbox")
);

export const EditProfile = lazy(() =>
	import("./AccountManagement/EditProfile/EditProfile")
);

export const CreateCarManagement = lazy(() =>
	import("./CarManagement/CreateCarManagement")
);

export const EditCarManagement = lazy(() =>
	import("./CarManagement/EditCarManagement")
);

export const CarDetail = lazy(() => import("./CarManagement/CarDetail"));

export const BlackoutManagement = lazy(() =>
	import("./BlackoutManagement/BlackoutManagement")
);

export const CreateBlackoutManagement = lazy(() =>
	import("./BlackoutManagement/CreateBlackoutManagement")
);

export const PromoCodeManagement = lazy(() =>
	import("./PromoCodeManagement/PromoCodeManagement")
);

export const CreatePromoCode = lazy(() =>
	import("./PromoCodeManagement/CreatePromoCode")
);

export const EditPromoCode = lazy(() =>
	import("./PromoCodeManagement/EditPromoCode")
);

export const DuplicatePromoCode = lazy(() =>
	import("./PromoCodeManagement/DuplicatePromoCode")
);

export const EditBlackoutManagement = lazy(() =>
	import("./BlackoutManagement/EditBlackoutManagement")
);

export const BookingManagement = lazy(() =>
	import("./BookingManagement/BookingManagement")
);

export const CreateBookingManagement = lazy(() =>
	import("./BookingManagement/CreateBookingManagement")
);

export const BookingDetails = lazy(() =>
	import("./BookingManagement/BookingDetails/BookingDetails")
);

export const BookingReceipt = lazy(() =>
	import("./BookingManagement/BookingDetails/BookingReceipt/BookingReceipt")
);

export const CmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/CMSAccountManagement")
);

export const CreateCmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/CreateCMSAccountManagement")
);

export const EditCmsAccountManagement = lazy(() =>
	import("./CMSAccountManagement/EditCMSAccountManagement")
);
